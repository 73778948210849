<template>
	<article class="view-centers">
		<div class="site-wrapper rythm-v">
			<header class="rythm-v">
				<h1>Gestion des centres</h1>

				<div role="toolbar"
						 aria-label="Actions sur les centres"
						 class="toolbar flex-row flex-align-left flex-center-v card shadow">
					<button class="button--outline-inverse"
									@click="editCenter()">
						<svg role="img"
								 aria-labelledby="button-add-new-title"
								 xmlns="http://www.w3.org/2000/svg"
								 width="24"
								 height="24"
								 viewBox="0 0 24 24"
								 fill="none"
								 stroke="currentColor"
								 stroke-width="2"
								 stroke-linecap="round"
								 stroke-linejoin="round"
								 class="icon feather feather-user-plus">
              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
              <circle cx="8.5" cy="7" r="4" />
              <line x1="20" y1="8" x2="20" y2="14" />
              <line x1="23" y1="11" x2="17" y2="11" />
            </svg>
						<span class="caption"
									id="button-add-new-title">Ajouter un centre</span>
					</button>
				</div>
			</header>

			<section class="section--edit card shadow rythm-v"
							 v-if="center">
				<h2 v-if="center.id">Éditer le centre {{ center.name }}</h2>
				<h2 v-else>Ajouter un centre</h2>
				<div>
					<form method="post"
								@submit.prevent="saveCenter">
						<div class="flex-row"
								 style="align-items: last baseline; flex-wrap: wrap;">
							<div class="flex-item center-number">
								<div class="input-group required">
									<label for="name">Numéro de centre <span class="hint">(format XX)</span></label>
									<input type="text"
												 name="number"
												 minlength="2"
												 maxlength="2"
												 id="number"
												 v-model="center.number"
												 required />
								</div>
							</div>

							<div class="flex-item flex-grow">
								<div class="input-group required">
									<label for="name">Nom</label>
									<input type="text"
												 name="name"
												 id="name"
												 v-model="center.name"
												 required />
								</div>
							</div>

							<div class="flex-item--100">
								<div class="input-group required">
									<label for="address">Adresse</label>
									<input type="text"
												 name="address"
												 id="address"
												 v-model="center.address"
												 required />
								</div>
							</div>

							<div class="flex-item--50">
								<div class="input-group">
									<label for="address">Numéro de téléphone</label>
									<input type="text"
												 name="phone"
												 id="phone"
												 v-model="center.phone" />
								</div>
							</div>

							<!-- <div class="flex-item--50">
								<div class="input-group">
									<label for="fax">Numéro de fax</label>
									<input type="text"
												 name="fax"
												 id="fax"
												 v-model="center.fax" />
								</div>
							</div> -->

							<div class="flex-item--50">
								<div class="input-group--inline">
									<label>
										<input type="checkbox"
													 name="center_ancillary_study"
													 value="1"
													 v-model="center.ancillary_study" />
										<span class="caption">Participe à l'étude ancillaire</span>
									</label>
								</div>
							</div>

							<div class="flex-item--100">
								<div class="input-group required">
									<label for="center">Référent</label>
									<select name="referrer"
													id="referrer"
													v-model="center.user_id"
													v-if="referrers">
										<option value="">Choisir un référent</option>
										<option v-for="(referrer, index) in referrers"
														:value="referrer.id"
														:key="`opt_${index}`">
											{{ `${referrer.firstname} ${referrer.lastname}` }}
										</option>
									</select>
								</div>
							</div>

							<div class="input-group--controls flex-item--100 flex-row flex-center-v flex-align-right rythm-h">
								<button type="reset"
												class="button--secondary"
												@click="cancelCenterEdit()">
									<svg class="icon icon-check"
											 role="img"
											 xmlns="http://www.w3.org/2000/svg"
											 width="18"
											 height="13"
											 fill="none"
											 viewBox="0 0 18 13">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                      clip-rule="evenodd"
                    />
                  </svg>
									<span class="caption">Annuler</span>
								</button>

								<button type="submit"
												class="button--primary">
									<svg class="icon icon-check"
											 role="img"
											 xmlns="http://www.w3.org/2000/svg"
											 width="18"
											 height="13"
											 fill="none"
											 viewBox="0 0 18 13">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                      clip-rule="evenodd"
                    />
                  </svg>
									<span class="caption">Enregister</span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>

			<section class="section--centers card shadow rythm-v"
							 v-if="centers">
				<h2>Liste des centres</h2>

				<block-quote class="flex-item--auto flex-item--grow">
					Les <router-link to="/users">utilisateurs</router-link> avec le rôle
					<em>"Référent"</em> peuvent être associés à un centre.
				</block-quote>

				<vue-good-table v-show="centersTable.rows.length"
												:fixed-header="true"
												:columns="centersTable.columns"
												:rows="centersTable.rows"
												:paginate="true"
												:pagination-options="{
													enabled: true,
													nextLabel: 'suivant',
													prevLabel: 'précédent',
													rowsPerPageLabel: 'lignes par page',
													ofLabel: 'sur',
													pageLabel: 'page', // for 'pages' mode
													allLabel: 'Toutes',
												}"
												:globalSearch="true"
												:search-options="{
													enabled: true,
													skipDiacritics: true,
													placeholder: 'Rechercher dans cette liste',
													externalQuery: searchTerms,
												}"
												styleClass="vgt-table striped">

					<template slot="table-column"
										slot-scope="props">
						<span v-if="props.column.field == 'actions'"
									class="offscreen">Actions</span>
						<span v-else>{{ props.column.label }}</span>
					</template>

					<template slot="table-row"
										slot-scope="props">

						<!-- Default columns -->
						<span v-if="['actions', 'email', 'name'].indexOf(props.column.field) === -1">{{ props.row[props.column.field] }}</span>

						<!-- link to dashboard with center id -->
						<span v-if="props.column.field == 'name'">
							<span v-if="props.column.field == 'name'">
								<router-link :to="`/cases?center_id=${props.row.id}`">{{ props.row[props.column.field] }}</router-link>
							</span>
						</span>


						<!-- Email column -->
						<span v-if="props.column.field === 'email'">
							<a :href="'mailto:' + props.row[props.column.field]">{{
								props.row[props.column.field]
							}}</a>
						</span>

						<!-- Action column -->
						<!-- Edit button -->
						<button class="button--icon"
										v-if="props.column.field == 'actions'"
										@click="editCenter(props.row.id)">
							<svg focusable="false"
									 role="img"
									 aria-labelledby="button-edit-title"
									 xmlns="http://www.w3.org/2000/svg"
									 width="24"
									 height="24"
									 viewBox="0 0 24 24"
									 fill="none"
									 stroke="currentColor"
									 stroke-width="2"
									 stroke-linecap="round"
									 stroke-linejoin="round"
									 class="icon icon-edit">
                <title id="button-edit-title">
                  Modifier le centre
                </title>
                <path
                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                />
                <path
                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                />
              </svg>
						</button>

						<!-- Remove button -->
						<button class="button--icon"
										v-if="props.column.field == 'actions' &&
											userCan('delete_centers', user)
										"
										@click="deleteCenter(props.row.id)">
							<svg aria-labelledby="button-remove-title"
									 focusable="false"
									 role="img"
									 xmlns="http://www.w3.org/2000/svg"
									 width="24"
									 height="24"
									 viewBox="0 0 24 24"
									 fill="none"
									 stroke="currentColor"
									 stroke-width="2"
									 stroke-linecap="round"
									 stroke-linejoin="round"
									 class="icon feather feather-trash-2">
                <title id="button-remove-title">
                  Supprimer
                </title>
                <polyline points="3 6 5 6 21 6" />
                <path
                  d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                />
                <line x1="10" y1="11" x2="10" y2="17" />
                <line x1="14" y1="11" x2="14" y2="17" />
              </svg>
						</button>
					</template>
				</vue-good-table>
			</section>
		</div>
	</article>
</template>

<style lang="scss">
@import "~@/scss/common-views.scss";
</style>

<style lang="scss" scoped>
.flex-item.center-number {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 30ch;
}
</style>

<script>
// import searchBox from "@/components/searchBox.vue";
import BlockQuote from "@/components/BlockQuote.vue";

// Event Bus
import { EventBus } from "@/event-bus.js";

// Vue Good Table
import { VueGoodTable } from "vue-good-table";

export default {
	name: "Centers",
	components: {
		VueGoodTable,
		BlockQuote,
	},

	data: function () {
		return {
			referrers: [],
			center: null,
			centers: [],
			centersTable: {
				// custom table search input (in toolbar)
				searchTerms: "",
				columns: [
					{
						label: "N°",
						field: "number",
						sortable: true,
						sortFn: this.sortNumeric,
						tdClass: "row-number",
					},
					{
						label: "Nom",
						field: "name",
						sortable: true,
						sortFn: this.invertOrder,
						tdClass: "row-name",
					},
					{
						label: "Adresse",
						field: "address",
						sortable: true,
						sortFn: this.invertOrder,
						tdClass: "row-address",
					},
					{
						label: "Téléphone",
						field: "phone",
						sortable: true,
						sortFn: this.sortNumeric,
						tdClass: "row-phone",
					},
					{
						label: "Patients",
						field: "case_count",
						sortable: true,
						sortFn: this.sortNumeric,
						tdClass: "row-case-count",
					},
					// {
					//   label: "Fax",
					//   field: "fax",
					//   sortable: true,
					// sortFn: this.invertOrder,
					//   tdClass: "row-fax",
					// },
					{
						label: "Action",
						field: "actions",
						sortable: false,
						tdClass: "row-actions",
					},
				],
				rows: [],
			},
		};
	},

	methods: {


		// update code to sort by numeric value
		sortNumeric: (x, y, col, rowX, rowY) => {
			return x - y;
		},

		// Invert column order
		invertOrder(x, y, col, rowX, rowY) {
			return 1;
		},

		// Delete Case (confirm…)
		deleteCenter(centerID) {
			if (!window.confirm("Supprimer le centre ?")) return false;

			this.$store
				.dispatch("DELETE_CENTER", centerID)
				.then((response) => {
					this.setupTable();
				})
				.catch((error) => {
					console.warn(error);
				});
		},

		// Close Add/Edit form
		cancelCenterEdit(event) {
			this.center = null;
		},

		// Insert/Update new center
		saveCenter: function (event) {
			this.$store
				.dispatch("SAVE_CENTER_DATA", this.center)
				.then((response) => {
					// Close edit
					this.cancelCenterEdit();

					// refresh centers list
					this.setupTable();
					this.scrollToTop();

					// Analytics
					EventBus.$emit("trackEvent", "Centres", "Centre ajouté ou modifié");

					// Toast
					let toast = this.$toasted.global.appSuccess({
						message: "<strong>Le centre est enregistré</strong>",
					});
					setTimeout(function () {
						toast.goAway();
					}, 5000);
				})
				.catch((error) => {
					console.warn(error);
				});
		},

		// Edit/New Center
		editCenter: async function (centerID = false) {
			// Reset current center
			this.center = {};

			// Load referrers users
			this.referrers = await this.getReferrers();

			// Load center (if editing)
			if (centerID) {
				this.center = await this.getCenterData(centerID);
			}

			this.scrollToTop();
		},

		// Load center
		getCenterData: function (centerID) {
			let center = this.$store
				.dispatch("GET_CENTER_DATA", centerID)
				.then((response) => {
					return response;
				})
				.catch((error) => {
					console.warn(error);
					return {};
				});
			return center;
		},

		// Get all users with a "Référent" role
		getReferrers: async function () {
			let users = this.$store
				.dispatch("GET_USERS", "Référent")
				.then((response) => {
					return response;
				})
				.catch((error) => {
					console.warn(error);
					return [];
				});

			return users;
		},

		// Get all centers
		getCenters: function () {
			let centers = this.$store
				.dispatch("GET_CENTERS")
				.then((response) => {
					return response;
				})
				.catch((error) => {
					console.warn(error);
					return [];
				});
			return centers;
		},

		// Setup table
		setupTable: async function () {
			this.centers = await this.getCenters();
			this.centersTable.rows = this.centers;
		},
	},

	computed: {
		searchTerms() {
			return this.$store.state.searchTerms;
		},
	},

	mounted() {
		// List centers
		this.setupTable();

		// Search event
		// EventBus.$on("search", (event) => {
		//   console.log("EventBus", event);
		//   this.usersTable.searchTerms = event.terms;
		// });
	},
};
</script>
